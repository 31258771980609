.wrapper {
  text-align: center;
}

.wrapper :global(.ant-modal-body) {
  padding: 60px;
  box-sizing: border-box;
}

.title:global(.ant-typography) {
  margin-bottom: 60px;
  color: var(--gray-9);
}

.image {
  margin-bottom: 60px;
}

.subtitle:global(.ant-typography) {
  margin-bottom: 16px;
  color: var(--gray-8);
}

.description:global(.ant-typography) {
  display: block;
  margin-bottom: 40px;
  color: var(--gray-8);
}
