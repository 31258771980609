.wrapper {
  background: var(--grey-4);
  display: flex;
  flex: 1;
  justify-content: center;
}

.card {
  min-width: 240px;
  text-align: center;
  padding: 32px;
  padding-bottom: 20px;
}

.spinner {
  margin-bottom: 24px;
}

.card > * {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
